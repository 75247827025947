var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.TextDetail),expression:"TextDetail"}],staticClass:"text-detail"},[(_vm.banners && _vm.banners.length)?_c('TextMaterialsSlider',{attrs:{"banners":_vm.banners,"title":_vm.TextDetail.name,"color":'#fff'}}):_c('div',{staticClass:"content"},[_c('div',{staticClass:"text-detail__title",domProps:{"innerHTML":_vm._s(_vm.TextDetail.name)}})]),_c('Breadcrumbs',{attrs:{"pages":[
        { name: 'Препараты  АЗ', link: { name: 'Medications' } },
        {
          name: 'ЭНХЕРТУ',
          link: {
            name: 'DrugsTemplates',
            params: {
              medication: 'enhertu',
            },
          },
        },
        {
          name: 'HER2-слабоположительный неоперабельный или метастатический рак молочной железы',
        } ]}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"text-detail__row mt-sm-8 mt-16"},[_c('div',[_c('Her2content')],1),_c('div',{staticClass:"sticky"},[(_vm.asideItems && _vm.asideItems.length)?_c('RightAside',{attrs:{"items":_vm.asideItems},on:{"medicationsClick":_vm.medicationsClick,"calendarClick":_vm.calendarClick,"nosologyClick":_vm.nosologyClick}}):_vm._e()],1)]),_c('div',{staticClass:"text-detail__buttons"},[_c('div',{staticClass:"text-detail__load button button_pink",on:{"click":function($event){return _vm.back()}}},[_c('span',{staticClass:"mr-2 mt-n-1"},[_c('svg',{attrs:{"width":"11","height":"18","viewBox":"0 0 11 18","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M9 1.5L1.5 9L9 16.5","stroke":"white","stroke-width":"1.5","stroke-linecap":"square"}})])]),_vm._v(" Назад ")]),_c('div',{staticClass:"text-detail__up button button_empty-pink",on:{"click":function($event){return _vm.toTop()}}},[_vm._v(" К началу страницы "),_c('span',{staticClass:"ml-2"},[_c('svg',{attrs:{"width":"18","height":"10","viewBox":"0 0 18 10","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1.25 8.75012L8.75 1.25012L16.25 8.75012","stroke":"currentColor","stroke-width":"1.5","stroke-linecap":"square"}})])])])])]),(
        _vm.TextDetail.nosology &&
        _vm.TextDetail.nosology.length &&
        _vm.sliderItems.length
      )?_c('LibrarySlider',{attrs:{"nosology":_vm.TextDetail.nosology[0],"items":_vm.sliderItems,"page":'TextDetail'},on:{"clickMaterials":_vm.clickMaterials,"toNosol":_vm.toNosol}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }